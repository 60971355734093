const siteMap = [
  {
    name: 'Resume',
    background: '../static/images/resume.png',
    link: 'Home',
  },
  {
    name: 'Blog',
    background: '../static/images/blog.png',
    link: 'Blog',
  },
  {
    name: 'Works',
    background: '../static/images/works.png',
    link: 'Works',
  },
];

export default siteMap;
